import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import site from "data/site.json";

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderEight";
import BlogDetailsContent from "container/blog-details";
import Footer from "container/footer/FooterOne";

const CookiePolicy = () => {
    const content = `
    Bu websitesi çerez kullanmaktadır.

    Sunduğumuz içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve veri trafiğimizi analiz etmek için çerezler kullanıyoruz. Ayrıca, sitemizi kullanımınızla ilgili bilgileri, onlara sağladığınız veya hizmetlerini kullanımınızdan topladıkları diğer bilgilerle birleştirebilecek sosyal medya, reklamcılık ve analiz ortaklarımızla paylaşıyoruz.
    
    ÇEREZ POLİTİKASI
    
    European Web Sitesi (“Web Sitesi”) olarak ziyaretçilerimize Web Sitesinde gezinirken daha iyi bir deneyim sağlamak için “çerez” kullanır. Çerezlerle, kullanıcı tercihlerini saklıyor ve yönetiyor, hedeflenmiş reklamlar sağlıyor, içeriği etkinleştiriyor ve analitik ve kullanım verilerini topluyoruz. Çerezlerin kullanımı, web siteleri ve uygulamalar, web siteleri üzerindeki çevrimiçi faaliyetleriniz hakkında bilgilerin toplandığı web siteleri ve uygulamalar arasında standart bir prosedürdür. Tanımlama bilgilerini ve benzer teknolojileri nasıl kullandığımız ve bunları nasıl kontrol edip yönetebileceğiniz hakkında ek bilgileri aşağıda bulabilirsiniz.
    
    “Çerez” Nedir?
    
    Çerez, bilgisayar, sistem veya dahili etkinleştirilmiş bir cihaz kullanıcısı Web Sitesini ziyaret ettiğinde depolanan bir metin dosyası veya bilgi parçasıdır. Bu dosyalar veya bilgi parçaları, kullanıcıyı veya cihazı tanımlamak ve bilgi toplamak için kullanılır.
    
    Çerezler bireysel kullanıcıyı bizzat tanımlamaz, sadece rastgele oluşturulmuş bir tanımlama etiketi aracılığıyla kullandığınız cihazı tanımlar. Bu bilgilerden kullanıc kişisel olarak tanımlanamaz, kişisel bilgilerine erişilemez.
    
    Çerezlerin Yönetimi

Kullanıcılar, Web Sitesi çerezlerini tarayıcı ayarlarından yönetebilirler. Kullanıcı olarak çerezleri devre dışı bırakabilir ve engelleyebilir veya silebilirsiniz. Ancak, çerezlerin devre dışı bırakılması durumunda, Web Sitesindeki kullanıcı deneyiminizi etkileyebileceğini lütfen unutmayın. Bu gibi durumlarda, Web Sitesinin belirli işlevlerinin ve özelliklerinin amaçlandığı gibi çalışmayabileceğini gözlemleyebilirsiniz.
    `;

    return (
        <Fragment>
            <Helmet>
                <title>Çerez Politikası | {site.title}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <BlogDetailsContent sidebar={false} blogTitle="Çerez Politikası" blogContent={content}/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default CookiePolicy;
