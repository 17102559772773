import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import heroBannerBg from "assets/img/revolution/slider-home-start-ups-slide-01-bg.jpg";
import heroImage from "assets/img/revolution/slider-home-start-ups-slide-01-image-01.png";
import Button from "components/button";

const HeroStartup = () => {
    return (
        <div className="hero-banner-wrapper startup" style={{backgroundImage: `url(${heroBannerBg})`}}>
            <div className="hero-image">
                <img src={heroImage} alt="brook-startup"/>
            </div>

            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="hero-banner-content hero-banner-content--startup">
                            <h2>Delaware'da Şirket</h2>
                            <p>Amerika’da şirket kurulumu, EIN & ITIN numarası ile banka hesap alımı, marka tescili, vergi/muhasebe ve yıllık danışmanlık hizmetleri sunmaktayız.</p>

                            <div className="mt--40 mt_sm--25">
                                <Button
                                    size="sd"
                                    type="link"
                                    lineType="internal"
                                    btnStyle="bullet"
                                    btnLink="/hizmetlerimiz"
                                    color="theme-border"
                                    btnText="Tüm Hizmetlerimiz"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeroStartup;
