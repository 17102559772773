import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import site from "data/site.json";

import PageContainer from "container/page";
import Header from "components/header/HeaderTwo";
import Breadcrumb from "components/breadcrumb/BreadcrumbFive";
import IconBoxes from "container/icon-box/pages/IconBoxOne";
import ServicesTitle from "container/work/WorkFive";
import Services from "container/service/ServiceNine";
import OtherServices from "container/service/SerivceEleven";
import OtherServices2 from "container/service/ServiceTwelve";
import OtherServices3 from "container/service/ServiceThirteen";
import OtherServices4 from "container/service/ServiceFourteen";
import CallToAction from "container/call-to-action/pages/CallToActionOne";
import Footer from "container/footer/FooterOne";

const AboutUs = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Hizmetlerimiz | {site.title}</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-1" revealFooter={false}>
                <Header/>
                <Breadcrumb
                    title={"Amerika’da Şirket Kurma <br/> <p style=\"font-size: 2rem;\">En hızlı ve en kolay şekilde şirket kurmak için ihtiyacınız olan tüm hizmetleri sağlamaktayız.</p>"}
                />
                <IconBoxes/>
                <ServicesTitle title="Hizmetlerimiz" subtitle="Amerika’da yaşarken veya şirket kurarken ihtiyacınız olabilecek tüm hizmetleri sağlamaktayız." />
                <Services/>
                <ServicesTitle title="Diğer Hizmetlerimiz" subtitle="Kurumsal Belgeler ve Değişiklikler" />
                <OtherServices/>
                <ServicesTitle title="" subtitle="Şirketin sürekliliği ile ilgili belgeler" />
                <OtherServices2/>
                <ServicesTitle title="" subtitle="Telif Hakkı, Marka, Patent ve Fikri Mülkiyet Tescili" />
                <OtherServices3/>
                <ServicesTitle title="" subtitle="Vergiler, Lisanslar ve İzinler" />
                <OtherServices4/>
                <CallToAction bgColor={"#0038E3"}/>
                <Footer/>
            </PageContainer>
        </Fragment>
    );
};

export default AboutUs;