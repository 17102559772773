import WOW from "wowjs";
import React, {useEffect} from 'react';
import ScrollToTop from "./utils/scrollToTop";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Helmet} from "react-helmet";

import Error from "./pages/site/Error";
import Pricing from "./pages/site/Pricing";
import Contact from "./pages/site/Contact";
import Services from "./pages/site/Services";
import Home from "./pages/site/Home";
import PrivacyPolicy from "pages/site/PrivacyPolicy";
import TermsOfService from "pages/site/TermsOfService";
import CookiePolicy from "pages/site/CookiePolicy";
import LegalWarning from "pages/site/LegalWarning";

const App = () => {
    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "956d3840-42a1-4dc2-a533-76e2abea5be1";
    
        (function() {
          var d = document;
          var s = d.createElement("script");
    
          s.src = "https://client.crisp.chat/l.js";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();

        new WOW.WOW({
            live: false
        }).init();
    }, []);
    return (
        <Router>
            <Helmet>
                <meta name="description" content="Amerika’da şirket kurulumu, EIN & ITIN numarası ile banka hesap alımı, marka tescili, vergi/muhasebe ve yıllık danışmanlık hizmetleri sunmaktayız." />
                <meta name="keywords" content="delaware, amerika, şirket kurmak, delaware'da şirket, amerika'da şirket" />
                <meta name="author" content="Delaware'da Şirket" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:title" content="Delaware'da Şirket" />
                <meta property="og:description" content="Amerika’da şirket kurulumu, EIN & ITIN numarası ile banka hesap alımı, marka tescili, vergi/muhasebe ve yıllık danışmanlık hizmetleri sunmaktayız." />
                <meta property="og:url" content="https://delawaredasirket.com" />
                <meta property="og:site_name" content="Delaware'da Şirket" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="tr_TR" />
            </Helmet>
            <ScrollToTop>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL + '/gizlilik-politikasi'}`}
                        component={PrivacyPolicy}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/hizmet-sartlari'}`}
                        component={TermsOfService}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/cerez-politikasi'}`}
                        component={CookiePolicy}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/yasal-uyari'}`}
                        component={LegalWarning}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/ucretler'}`}
                        component={Pricing}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/iletisim'}`}
                        component={Contact}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/hizmetlerimiz'}`}
                        component={Services}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/'}`}
                        component={Home}
                    />
                    <Route exact component={Error}/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
};

export default App;