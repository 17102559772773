import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import site from "data/site.json";

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderFive";
import Footer from "container/footer/FooterOne";
import HeroStartup from "container/hero-banner/HeroStartup";
import WorkingProcess from "container/work/WorkOne";
import Portfolios from "container/portfolio/homes/PortfolioSeven";
import CallToAction from "container/call-to-action/pages/CallToActionOne";

const HomeStartup = () => {
    return (
        <Fragment>
            <Helmet>
                <title>{`Ana Sayfa | ${site.title}`}</title>
            </Helmet>
            <LayoutDefault className="template-color-5 template-font-1" revealFooter={false}>
                <Header
                    transparent={true}
                />
                <HeroStartup/>
                <WorkingProcess className="startup"/>
                <Portfolios className="bg_color--5"/>
                <CallToAction bgColor={"#0038E3"}/>
            </LayoutDefault>
            <Footer/>
        </Fragment>
    );
};

export default HomeStartup;