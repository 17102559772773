import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderEight";
import BlogDetailsContent from "container/blog-details";
import Footer from "container/footer/FooterOne";

const TermsOfService = () => {
    const content = `

\nBu Hizmet Şartları, European LLC’in ve onun tüm bağlı ve ilgili kuruluşlarının tüm web siteleri için geçerlidir (toplu olarak “Web Sitesi”). Web Sitesi, European LLC’nin (“European” veya “Şirket”) ve lisans sahiplerinin mülküdür. Siteyi kullanarak, bu Hizmet Şartlarını ve diğer Şirket politikalarına ve Gizlilik Politikasına bağlı kalmayı kabul edersiniz. Kabul etmiyorsanız, lütfen Siteyi kullanmayınız.

\nUyarı
\n
\nEuropean LLC, onun çalışanları ve temsilcileri, İç Gelir Servisi (“IRS”) veya başka herhangi bir hükümet veya kurum veya kuruluşları ile bağlantılı değildir. European, IRS’den Federal Vergi Kimlik Numaralarını almak için ücretli hizmetler sunar. Şirket, IRS Form SS-4 uyarınca “Üçüncü Taraf Temsilcisi” olarak, müşterileri adına İşveren Kimlik Numarası (“EIN”) için başvuruları hazırlar ve IRS’ye sunar. European EIN başvurularını onaylamaz ve sağlanan bilgilerin doğruluğundan sorumlu değildir. Herhangi bir birey, www.irs.gov adresindeki resmi IRS web sitesi aracılığıyla kendi EIN başvurusunu alabilir.
\n
\nBuradaki hiçbir şey yasal tavsiye niteliğinde değildir. Chat, e-posta veya bu Web Sitesinin başka herhangi bir şekilde kullanılması veya bu bilgilerin iletilmesi yoluyla bu bilgilerin alınması bir avukat-müvekkil ilişkisi oluşturmayı amaçlamaz ve bu bilgilerin alınması bir avukat-müvekkil ilişkisi oluşturmaz.
\n
\nEuropean, Web Sitesinin, Web Sitesindeki herhangi bir içeriğin, hizmetinin veya özelliğinin hatasız veya kesintisiz olacağını veya herhangi bir kusurun düzeltileceğini veya Web Sitesini kullanımınızın belirli sonuçlar sağlayacağını taahhüt etmez. Web sitesi ve içeriği “mevcut olduğu sürece” sunulur. Web Sitesinde verilen tüm bilgiler önceden haber verilmeksizin değiştirilebilir. European, Web Sitesini kullanımınızla bağlantılı veya bununla ilgili herhangi bir üçüncü tarafın eylemleri, ihmalleri ve davranışları için her türlü sorumluluğu reddeder. Web Sitesini ve bağlantılı siteleri kullanımınızdan tamamen siz sorumlusunuz. Web Sitesinden veya herhangi bir içerikten memnuniyetsizlik durumunda Şirkete karşı tek çözüm yolunuz Web Sitesini veya bu tür içeriği kullanmayı bırakmaktır.
\n
\nYukarıdaki sorumluluk reddi, herhangi bir performans hatası, hata, ihmal, kesinti, silme, kusur, işlem veya iletimde gecikme, bilgisayar virüsü, iletişim hattı arızası, hırsızlık veya imha veya yetkisiz erişimin neden olduğu her türlü hasar ve sorumluluk için geçerlidir.
\n
\nEuropean, aşağıdakilerden herhangi birini herhangi bir zamanda bildirimde bulunmaksızın yapma hakkını saklı tutar: (1) herhangi bir nedenle Web Sitesini veya Web Sitesinin herhangi bir bölümünü değiştirme, askıya alma veya sona erdirme; (2) Web Sitesini veya Web Sitesinin herhangi bir bölümünü ve geçerli tüm politikaları veya şartları değiştirmek veya değiştirmek; ve (3) Rutin veya rutin olmayan bakım, hata düzeltme veya diğer değişiklikleri gerçekleştirmek için gerektiği şekilde Web Sitesinin veya Web Sitesinin herhangi bir bölümünün çalışmasını kesintiye uğratmak.
\n
\nİçerik
\n
\nEuropean, Web Sitesinde sunulan herhangi bir ürün veya hizmette veya bu tür ürünler veya hizmetler için geçerli fiyatlarda herhangi bir zamanda bildirimde bulunmaksızın değişiklik yapabilir. Ürünler ve hizmetler ile ilgili olarak Web Sitesindeki materyaller güncel olmayabilir ve Web Sitesindeki materyalleri bu tür ürünler ve servislerle ilgili olarak güncelleme taahhüdünde bulunmamaktadır.
\n
\nTelif, Marka, Patent
\n
\nWeb Sitesindeki tüm gönderiler, metinler, grafikler, kullanıcı arayüzleri, görsel arayüzler, fotoğraflar, ticari markalar, logolar, sanat çalışmaları (topluca “İçerik”) European LLC’nin mülkiyetindedir, kontrolü ve lisansı European LLC’ye aittir ve ticari marka kanunları ve çeşitli diğer fikri mülkiyet hakları ve haksız rekabet kanunları ile korunmaktadır.
\n
\nBu Hizmet Şartlarında açıkça belirtilmedikçe, Web Sitesinin hiçbir bölümü içeriği kopyalanamaz, çoğaltılamaz, yeniden yayınlanamaz, yüklenemez, postalanamaz, kamuya açık olarak gösterilemez, kodlanamaz, tercüme edilemez, iletilemez veya herhangi bir şekilde başka bir bilgisayara, sunucuya dağıtılamaz, European’in önceden açık yazılı izni olmaksızın, yayın veya dağıtım veya herhangi bir ticari işletme için web sitesi veya diğer ortamlarda kullanılamaz.
\n
\nYükümlülükler
\n
\nBu Web Sitesine erişerek erişim için gerekli tüm düzenlemeleri yapmayı; bu ve Web Sitesindeki diğer ilgili şirket politikalarından haberdar olduğunuzu; doğru iletişim bilgilerinizi sağladınızı kabul etmektesiniz.
\n
\nWeb Sitesinin herhangi bir kısmına veya özelliğine veya Siteye bağlı diğer sistemlere veya ağlara veya Web Sitesi üzerinde veya bunlar aracılığıyla sunulan hizmetlerden herhangi birine bilgisayar korsanlığı, şifre “madenciliği”, herhangi bir şekilde yetkisiz erişim sağlamaya veya diğer meşru olmayan faaliyetlerde bulunmamayı kabul etmektesiniz. Web Sitesinin düzgün çalışmasına veya Sitede gerçekleştirilen herhangi bir işleme veya başka herhangi bir kişinin Web Sitesini kullanımına müdahale etmek veya müdahale etmeye teşebbüs etmek için herhangi bir cihaz, yazılım veya rutin kullanamazsınız. Web Sitesini veya herhangi bir İçeriği, yasalara aykırı veya bu Hizmet Şartları tarafından yasaklanmış herhangi bir amaç için veya Şirketin veya başkalarının haklarını ihlal eden herhangi bir yasadışı faaliyet gerçekleştirilmesini istemek için kullanamazsınız.
\n
\nHizmet satın alımları, promosyonlar veya diğer benzer özellikler dahil olmak üzere Sitenin belirli kısımları veya özellikleri için ek hüküm ve koşullar geçerli olabilir; bunların tümü bu referansla bu Hizmet Şartlarının bir parçası haline getirilmiştir.
\n
\nÜcret ve Geri İade Politikası
\n
\nWeb Sitesinde listelenen ve burada referans olarak dahil edilen fiyatlar üzerinden hizmetler sunuyoruz. Tüm tutarlar faturalarımıza göre muaccel olup ödenir. Bize verilen tüm bilgilerin doğru olması koşuluyla, başvurunuzu belirtilen süre içinde işleme koymak için elimizden gelen her şeyi yapacağız. Süreci tamamlayamazsak, herhangi bir EIN, ITIN alınamaz veya düzeltilemez bir hata nedeniyle şirket oluşturulamazsa, işlem ücretinin % 25’i geri ödeme olarak tarafımızdan sağlanacaktır. Başvuruyu tamamlarsak ve bir EIN başarıyla atanırsa (hizmet sağlanır), geri ödeme yapılamaz. Siparişinizden hemen sonra fikrinizi değiştirirseniz, işlem ücretinin% 25’i (yüzde) geri ödeme olarak tarafımızdan sağlanacaktır.
\n
\nMüşteriler tarafından sağlanan yanlış bilgilerden kaynaklanan eksik siparişler için, sorunu düzeltmek için müşteriyle e-posta yoluyla iletişime geçilecektir ve EIN’nizi almak için bir kez daha denenecektir. Bu noktada başka bir hata olursa, müşteriye bir sonraki iş gününde işlem ücretinin% 25’ini (yüzde) iade edilir. SS4 Faks – Ref 101: IRS’den Referans 101 hatasıyla sonuçlanan EIN uygulamaları için Social Entersprises LLC, başvurunuzu IRS’ye manuel olarak kaydetme işlemine nasıl devam edeceğimize ilişkin talimatlar ile e-posta yoluyla sizinle iletişime geçebilir. Ref 101 hatasının oluştuğu gün tamamlanmış bir SS -4 form PDF’si içeren bir e-posta alacaksınız ve bu PDF elektronik imzanızı gerektirecektir. Ardından Social Entersprises LLC, EIN başvurunuz için IRS ile iletişime devam edecek ve tüm Referans 101 hataları için manuel işlem bekleme süresi nedeniyle 7-10 iş günü içinde EIN’yi e-posta yoluyla alacaksınız. Ref 101 uygulamasında ek bir hatanın ortaya çıktığı ve Social Entersprises LLC’nin başvurunuzu işleme alamaması durumunda, işlem ücretinin 15 ABD doları eskik olarak iade edilecektir.
\n
\nGizlilik
\n
\nŞirket Gizlilik Politikası, bu Web Sitesinin kullanımı için geçerlidir ve şartları, bu referansla bu Hizmet Şartlarının bir parçası haline getirilmiştir. Web Sitesini kullanarak, İnternet aktarımlarının hiçbir zaman tamamen özel veya güvenli olmadığını kabul etmektesiniz. Web Sitesine gönderdiğiniz herhangi bir mesajın veya bilginin, belirli bir iletimin (örneğin, kredi kartı bilgilerinin) şifrelendiğine dair özel bir bildirim olsa bile başkaları tarafından okunabileceğini veya engellenebileceğini kabul etmektesiniz.
\n
\nEuropean, Şirketin yürürlükteki herhangi bir kanun, düzenleme, yasal süreç veya idari talebe uymak için gerekli gördüğü herhangi bir bilgiyi açıklama hakkını her zaman saklı tutar.
\n
\nSorumluluk
\n
\nKanunların yasakladığı durumlar haricinde, European, kar kaybı dahil olmak üzere hiçbir dolaylı, sonuç niteliğinde, örnek niteliğinde, arızi veya cezai zarar için sorumlu olmayacaktır.
\n
\nWeb Sitesini kullanmanızdan kaynaklanan veya bununla bağlantılı olarak herhangi bir üçüncü şahıs tarafından European’a karşı şirketi, çalışanları, yöneticilerini, hissedarlarını, öncüllerini, çıkar haleflerini, temsilcilerini, yan kuruluşlarını ve bağlı şirketlerini, yapılan herhangi bir talep, kayıp, sorumluluk, talep veya masraftan (avukat ücretleri dahil) zararsız olarak tazmin etmeyi kabul edersiniz.
\n
\nHizmet Şartlarının İhlali
\n
\nEuropean, tamamen kendi takdirine bağlı olarak ve önceden haber vermeksizin, Web Sitesine erişiminizi sona erdirebileceğini ve / veya Şirketin bu Hizmet Şartlarını veya diğer sözleşmeleri veya kuralları ihlal ettiğinizi belirlemesi durumunda gelecekte Web Sitesine erişiminizi engelleyebileceğini kabul etmektesiniz. Ayrıca European, tamamen kendi takdirine bağlı olarak ve önceden bildirimde bulunmaksızın, (1) kolluk kuvvetleri veya diğer devlet kurumlarının taleplerini (2) sizin tarafınızdan yapılan bir talep (kendi kendine başlatılan hesap silme), (3) Web Sitesinin veya Site üzerinde veya Site aracılığıyla sunulan herhangi bir hizmetin durdurulması veya önemli ölçüde değiştirilmesi veya (4) beklenmedik teknik sorunlar veya sorunlar içeren (ancak bunlarla sınırlı olmayan) nedenlerle Web Sitesine erişiminizi sonlandırabileceğini de kabul etmektediniz. European’ın bu Hizmet Şartlarının herhangi bir ihlali sonucunda Web Sitesine erişiminizin sonlandırılmasından size veya herhangi bir üçüncü tarafa karşı sorumlu olmayacağını kabul etmektesiniz.
\n
\nEuropean, bu Hizmet Şartlarını ihlal etmenizin bir sonucu olarak size karşı herhangi bir yasal işlem yaparsa, Şirket sizden tazminat alma hakkına sahip olacak ve tüm makul avukatlık ücretlerini ve benzer masraflarını ödemeyi kabul edersiniz.
\n
\nUyuşmazlık Çözümü\n\n
\n
\nTüm uyuşmazlıklar dahil olmak üzere Siteye erişiminiz veya Siteyi kullanımınızla ilgili tüm konuların, kanunlar ihtilafı hükümlerine bakılmaksızın Amerika Birleşik Devletleri kanunlarına ve Delaware Eyaleti kanunlarına tabi olacağını kabul etmektesiniz. Delaware’deki eyalet ve federal mahkemelerdeki kişisel yargı yetkisini ve yeri kabul eder ve bu tür yargı yetkisine veya mahkeme yerine herhangi bir itirazdan feragat etmektesiniz.

    `;

    return (
        <Fragment>
            <Helmet>
                <title>Hizmet Şartları | Delaware'da Şirket</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <BlogDetailsContent sidebar={false} blogTitle="Hizmet Şartları" blogContent={content}/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default TermsOfService;
