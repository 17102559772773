import React from 'react';
import Logo from "./elements/logo";
import Navbar from "./elements/navbar/Navbar";
import HeaderContainer from "container/header/HeaderContainerOne";

import logoBlack from 'assets/img/logo/brook-black.png'

const HeaderTwo = () => {
    return (
        <HeaderContainer
            logoVersion={'dark'}
            transparent={true}
            sticky={true}
            classes="position-from--top"
        >
            <div className="header-left">
                <Logo logo={logoBlack}/>
            </div>

            <Navbar/>
        </HeaderContainer>
    );
};

export default HeaderTwo;