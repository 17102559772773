import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ListItem from "components/list";

const WorkOne = ({className}) => {
    return (
        <div className={`brook-what-do-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 ${className}`}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-left">
                            <h5 className="heading heading-h3 theme-color">{'Neden Biz? 🙋‍♂️'}</h5>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h5">İşlemlerinizi kendi başınıza yapmanızla bizimle çalışmak arasındaki farklar nelerdir?</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="mt--40 mt_md--20 mt_sm--5 bk-list">
                    <p style={{ marginLeft: "1.5rem" }}>
                        Delaware’da bir limited şirket ya da ticari şirket kurarken işlemlerinizi dilerseniz kendiniz devlet makamlarına gerekli başvuruları yaparak ve evrakları teslim ederek yapabilirsiniz; ya da profesyonel bir firma ya da kişiyle çalışarak işlemlerinizi bu kişi veya kurumlar aracılığıyla yapabilirsiniz.
                    </p>

                    <Col lg={2}>
                        <div className="what-do mt--40 mt_md--5 mt_sm--5">
                            <div className="basic-separator line-3"/>
                        </div>
                    </Col>

                    <Col xl={4} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                text={'Profesyonel Destek 🙌🏻'}
                                content={"Limited ya da ticari şirketinizi bizimle kurmak istediğinizde tüm süreç sizi prosedür hakkında bilgilendirir şekilde ilerleyecek ve sizden işlemlerin başlamasının ardından bir ücret talep edilmeyecektir."}
                            />
                        </div>
                    </Col>

                    <Col xl={{span: 4, offset:1}} lg={5} md={6}>
                        <div className="what-do mt--40">
                            <ListItem
                                marker={null}
                                text={'%100 Gizlilik 💯'}
                                content={"İsminizin gizliligi bizim için önemlidir. Kayıtlarınızın halka açık olmaması ve gizliliğinizin korunması bizim için önceliktir."}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkOne;
