import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import IconBoxItem from "components/icon-box/IconBoxItemOne";

const ServiceThirteen = () => {
    return (
        <div className="brook-service-modern-wrapper">
            <div className="brook-service-modern-bottom">
                <Container fluid>
                    <Row>
                        <Col lg={10} className="pl--350 plr_lg--30 plr_md--50 plr_sm--50">
                            <Row>
                                {services.map(service => (
                                    <Col sm={4} key={service.id} className="wow move-up">
                                        <IconBoxItem
                                            data={service}
                                            border={true}
                                            padding={false}
                                            className="mt--70 mt_lg--20 mt_mobile--40"
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

const services = [
    {
        id: 1,
        title: "Marka Tescil Ettirme",
        iconClass: "ion-ios-flag-outline"
    },
    {
        id: 2,
        title: "Marka Tescili Araştırması",
        iconClass: "ion-ios-flag-outline"
    },
    {
        id: 3,
        title: "Tescil İhlali Takibi",
        iconClass: "ion-ios-flag-outline"
    },
    {
        id: 4,
        title: "Telif Hakkı",
        iconClass: "ion-ios-flag-outline"
    },
    {
        id: 5,
        title: "Patent",
        iconClass: "ion-ios-flag-outline"
    }
]

export default ServiceThirteen;
