import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import site from "data/site.json";

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderEight";
import BlogDetailsContent from "container/blog-details";
import Footer from "container/footer/FooterOne";

const LegalWarning = () => {
    const content = `
    
    Bu web sitesindeki materyal ve bilgiler European LLC. tarafından yalnızca bilgi amaçlı hazırlanmıştır. Buradaki hiçbir şey hukuki tavsiye niteliğinde değildir. Sohbet, e-posta veya bu sitenin başka herhangi bir şekilde kullanılması bir avukat-müvekkil ilişkisi oluşturmaz, bu bilgilerin iletilmesi bir avukat-müvekkil ilişkisi oluşturmayı amaçlamaz ve bu bilgilerin alınması bir avukat-müvekkil ilişkisi oluşturmaz. Bu bilgiler en güncel yasal gelişmeleri yansıtabilir veya yansıtmayabilir. Ziyaretçiler sunulan bilgilerin yanlış olduğunu veya bu bilgiler nedeniyle bir zarara uğradıklarını iddia edemezler. Bu nedenlerle okuyucular, profesyonel tavsiye almadan bu bilgilere göre hareket etmemelidir. European LLC. bu web sitesinde yer alan bilgilerle veya ziyaretçilerin bu web sitesinde sağlanan bilgilere dayanarak gerçekleştirdiği eylemlerle bağlantılı olarak hiçbir sorumluluk veya yükümlülük kabul etmez.

Bu web sitesindeki tüm dosyalar ve bilgiler European LLC’nin mülkiyetindedir. Bu web sitesinde yer alan yazılı materyaller ve bilgiler ile firmanın logosu ile ilgili fikri mülkiyet hakları firmaya aittir ve European LLC sahiplerinin yazılı izni olmadan kopyalanamaz, çoğaltılamaz veya kullanılamaz.
    
    `;

    return (
        <Fragment>
            <Helmet>
                <title>Yasal Uyarı | {site.title}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <BlogDetailsContent sidebar={false} blogTitle="Yasal Uyarı" blogContent={content}/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default LegalWarning;
