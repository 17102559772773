import React from 'react';
import {Link} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import Copyright from "components/footer/Copyright";

import Logo from "components/header/elements/logo";
import Widget from "components/widget";

import logo from '../../assets/img/logo/brook-white2.png';
import socials from '../../data/socials';

const FooterOne = ({className}) => {
    return (
        <footer
            className={`page-footer pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5 ${className}`}
        >
            <div className="bk-footer-inner pt--150 pb--30 pt_sm--100">
                <Container>
                    <Row>
                        <Col xs={12} sm={6} lg={4}>
                            <Widget
                                wrapClasses="text-var--2"
                                title={null}
                            >
                                <Logo logo={logo}/>
                                <div className="footer-inner">
                                    <p>Amerika'daki partneriniz</p>
                                </div>
                            </Widget>
                        </Col>

                        <Col xs={12} sm={6} lg={4} className="mt_mobile--40">
                            <Widget
                                wrapClasses="text-var--2 menu--about"
                                title="Dökümanlar"
                            >
                                <div className="footer-menu">
                                    <ul className="ft-menu-list bk-hover">
                                        <li><Link to={process.env.PUBLIC_URL + "/gizlilik-politikasi"}>Gizlilik Politikası</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/hizmet-sartlari"}>Hizmet Şartları</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/cerez-politikasi"}>Çerez Politikası</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/yasal-uyari"}>Yasal Uyarı</Link></li>
                                    </ul>
                                </div>
                            </Widget>
                        </Col>

                        <Col xs={12} sm={6} lg={4} className="mt_md--40 mt_sm--40">
                            <Widget
                                wrapClasses="text-var--2 menu--about"
                                title="Bize Ulaşın"
                            >
                                <div className="footer-address">
                                    <div className="bk-hover">
                                        <p>Telefon: <a style={{ top: "0.30rem" }} href="https://wa.me/+13023843323">+1 302-384-3323</a> </p>
                                        <p>E-posta: <a style={{ top: "0.30rem" }} href="mailto:info@delawaredasirket.com">info@delawaredasirket.com</a></p>
                                        <p>Adres: 126 Carlotta Dr, Bear, DE 19701, USA</p>
                                    </div>
                                    <div className="social-share social--transparent text-white">
                                        {socials.map(social => (
                                            <a key={social.id} href={`https://${social.media}.com/${social.username}`}>
                                                <i className={`fa fa-${social.media}`}/>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="copyright ptb--50 text-var-2">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={24} md={12}>
                            <div className="copyright-right text-md-right text-center">
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
};

FooterOne.defaultProps = {
    className: "bg_color--3"
}

export default FooterOne;