import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import site from "data/site.json";

import LayoutDefault from "layouts/LayoutDefault";
import Header from "components/header/HeaderEight";
import BlogDetailsContent from "container/blog-details";
import Footer from "container/footer/FooterOne";

const PrivacyPolicy = () => {
    const content = `
    
    Giriş

    European LLC (“European” veya “Biz” veya “Şirket”) olarak, kişisel verilerinizin korunmasına büyük bir önem veriyoruz. Bu çerçevede kişisel verilerinizi; neden ve nasıl topladığımızı, nasıl işlendiği ve kimlerle, hangi durumlarda paylaşılabileceği konularında sizleri bilgilendirmek istiyoruz.
    
    İşlediğimiz Kişisel Veriler
    
    Müşterilerimizin, olası müşterilerimizin ve internet sitemiz ziyaretçilerinin pek çok kişisel verisini topluyor ve işliyoruz. Topladığımız kişisel veriler sizinle olan etkileşime göre, bu internet sitemizde dolaşmak olsun veya almak istediğiniz hizmete göre dolduracağınız form olsun, değişiklik gösterebilmektedir. Pek çok durumda, kişisel verilerinizi bizimle paylaşıp paylaşmama konusunda kontrol tamamen sizin elinizdedir. Ancak belirtmek isteriz ki ihtiyaç duyulan kişisel verilerin paylaşılması durumunda sunduğumuz hizmetlerin tamamen etkili bir şekilde yararlanabilmeniz mümkün olmayabilir.
    
    Kişisel Verileriniz
    
    Bizim ile paylaştığınız veriler. İnternet sitemizdeki formları doldurarak veya iletişim e-posta adresi üzerinden paylaştığınız verileri işliyoruz. Bunlar:
    İsminiz ve Soy isminiz
    Uyruğunuz
    Telefon / WhatsApp Numaranız
    E-posta adresiniz
    Posta Adresiniz
    (Varsa) Teslimat adresiniz
    (Varsa) Şirketinize dair bilgiler, özellikle şirketinizin ticari unvanı ve şirket hissedarlarının ismi/soyismi
    Pasaport, bilgileriniz ve almak istediğiniz servise göre değişiklik gösterebilecek sair belgelerdeki bilgileri
    Sunulacak hizmetin durumuna göre gerekli görülmesi halinde ve varsa Amerika Birleşik Devletleri sosyal güvenlik numaranız (SSN), bireysel vergi kimlik numaranız (ITIN) ve işveren kimlik numaranız (EIN)
    Ödeme hallerinde, ödemeye dair finansal bilgiler
    İnternet sitemizin kullanımı sırasında, kullanıcıların genel olmak üzere kişinin IP adresi üzerinden bulunduğu konum, hangi sayfalarda dolaşıldığı ve en çok hangi sayfaya girdiği gibi verilere de erişebilmekteyiz. Ancak belirtmek isteriz ki, her ne kadar bu verilere erişimimiz olsa da bu tür veriler herhangi bir şekilde saklama veya depolama gibi bir işlemden geçirmemekteyiz.
    İnternet sitemize yaptığınız ziyaretleriniz sırasında internet sitemizle olan deneyiminizi geliştirmek için çerez (cookie) teknolojisinden faydalanmaktayız.
    Kişisel Verilerinizi Nasıl Topluyoruz
    
    İnternet sitemiz vasıtasıyla
    Başvuru formlarının doldurulması ile doğrudan tarafımıza sağladığınız veriler
    Otomatik olarak internet sitemizi kullanırken. Bu veriler, internet sitemizi kullanırken erişilebilen teknik verilerdir. Kullanım detayları, IP adresi ve “cookie” vasıtasıyla toplanan veriler
    Müşteri hizmetlerimiz ile sizin aranızdaki elektronik mesaj veya “chat” vasıtasıyla tarafımıza sağladığınız veriler
    info@delawaredasirket.com, info@enterprises.social veya diğer @delawaredasirket.com ve
    @enterprises.social ile biten e-posta adreslerine gönderilen e-postalarınız vasıtasıyla
    
    Talep ettiğiniz hizmet doğrultusunda birinci bölümde belirtilen veriler dışında bu talebinizi yerine getirmek adına durumdan duruma ilave belge ve bilgi gerekebilmektedir.
    Hizmetlerimiz ile ilgili yaşadığınız bir sıkıntı veya soruyu bize bildirirken birinci bölümde belirtilen dışındaki kişisel verilerinizi bizimle paylaşmış olabilirsiniz. Bu veriler de aynı şekilde koruma altındadır.
    Üçüncü parti kaynaklar
    Hizmetlerimize sizin adınıza başvuran kişiler (işveren, kullandığınız diğer aracı kurumlar gibi) tarafından sağlanan veriler
    Kamuya açık devlet veritabanları gibi amuya açık kaynaklar vasıtasıyla
    Neden Kişisel Verilerinizi Topluyoruz ve Nasıl İşliyoruz
    
    Başlıca sözleşmenin kurulması, sözleşmenin ifası, etkili bir hizmet sunmak ve hizmet kalitesini geliştirmek amacıyla açık rıza ile ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla Şirketimizin meşru menfaati hukuki sebeplerine dayalı ve bu sebeplerle sınırlı olarak kişisel verilerinizi topluyor ve işliyoruz. Başlıca:
    
    İnternet sitemizi ve içeriğini size sunmak
    Bizden talep edilen hizmetler hakkında daha detaylı bilgi verebilmek amacıyla
    Sözleşmenin kurulması ve Sözleşmenin ifası amacıyla
    Daha iyi bir müşteri hizmeti sağlayabilmek için
    Hukuki talepleri yerine getirmek adına (Mahkeme, kolluk kuvveti, düzenleyici kuruluş ve diğer kamu kurum talepleri)
    Kişisel verilerinizi tarafımıza sağladığınızda belirteceğimiz diğer nedenler. Daha spesifik kullanımlar için size neden böyle bir kullanımın gerektiğini de açıklayarak açık rızanızı tekrardan isteyeceğiz.
    
    Kişisel Verilerinizi Ne Kadar Süre ile Tutacağız
    
    Şirketimiz ilgili kişisel veriyi ve belgeyi size karşı olan sözleşmesel borç ve diğer sorumluluklarımızı yerine getirmemiz için gerekli olan süre boyunca tutmaktayız. Bizden talep ettiğiniz hizmetin tamamlanmasından sonra pek çok kişisel veriniz imha edilmektedir. Ancak, daha uzun süre tutulan bazı belgeleriniz de mevcuttur. Amerika Birleşik Devleti yetkililerinin denetimleri için varsa ITIN, EIN ve vergi beyanlarınızı daha uzun süre tutabilmekteyiz.
    
    Ödemeye ilişkin kayıtların saklanması herhangi bir süre ile kısıtlı değildir.
    
    Verilerin Açıklanması
    
    Kişisel verileriz, izniniz olmadan paylaşmayacağız, satmayacağız veya dağıtmayacağız. Sadece aşağıdaki durumlarda verilerinizin paylaşımı mümkün olabilir:
    
    Tarafımızca açıklanan bir amaç için.
    İşimizi yürütmek için kullandığımız yükleniciler ve hizmet sağlayıcılara. Bu yükleniciler ve hizmet sağlayıcıları da bu Politikaya bağlıdır ve bize hizmet sağlamak dışında herhangi bir amaçla kendilerine sunduğumuz kişisel verileri paylaşmalarına veya kullanmalarına izin verilmez. European, bu yükleniciler ve hizmet sağlayıcıların davranışlarını, gizlilik politikamıza ve gizlilik standartlarımıza uygun olup olmadıklarını düzenli olarak gözden geçirir ve denetler.
    European’ın mevcut ve gelecekte kurulabilecek bağlı şirketlerine. European’ı ilgilendiren bir birleşme, devralma veya transfer durumunda, kişisel verileriniz halef kuruluşa aktarılabilir. Bu tür bir aktarımdan vazgeçme seçeneği size bildirilecektir.
    Herhangi bir idari veya düzenleyici talebe yanıt vermek dahil olmak üzere herhangi bir mahkeme emrine, yasaya veya yasal sürece uymak. Zaman zaman, yasal talepler (örneğin, bir arama emri veya bir mahkeme emri) nedeniyle bilgilerinizi açıklamak gerekebilir. Bu, Amerika Birleşik Devletleri içindeki ve dışındaki yargı alanlarından gelen yasal taleplere, söz konusu yargı alanındaki yasalara uymak için gerekli olduğuna, söz konusu yargı alanındaki müşterileri etkilediğine ve uluslararası kabul görmüş standartlarla tutarlı olduğuna iyi niyetle inandığımızda yanıt vermeyi içerebilir.
    Social Enterprise ve sizin arasında imzalanan anlaşmaları uygulamak için.
    Web sitemiz aracılığıyla Social Enterprise ile iletişimin bir avukat-müvekkil ilişkisi oluşturmayı amaçlamadığını lütfen unutmayın. European LLC bir hukuk firması değildir, bu nedenle European ile paylaşılan, aktarılan veya iletilen herhangi bir bilgi, ayrıcalıklı müşteri-avukat bilgisi olarak kabul edilmez.
    
    European, ABD merkezli bir kuruluştur ve kişisel verileriniz, Amerika Birleşik Devletleri’nde ve bağlı şirketlerimizin, yüklenicilerimizin veya hizmet sağlayıcılarımızın bulunduğu diğer ülkelerde depolanabilir ve işlenebilir. Kişisel verileriniz uluslararası olarak aktarıldığında, AB Genel Veri Koruma Yönetmeliği ve sözleşmeye dayalı koruma tedbirleri dahil olmak üzere veri koruma yasalarına uygun olarak uygun korumalara tabi olmasını sağlamak için önlemlerimiz mevcuttur.
    
    Rızanızla Web sitemizde hizmetlerimize dair görüşlerinizi yayınlayabiliriz. Referansınızı güncellemek veya silmek isterseniz, lütfen info@delawaredasirket.com adresinden bizimle iletişime geçin.
    
    Kişisel Verilerinizi Nasıl Koruyoruz
    
    European kişisel bilgileri herhangi bir kayıp, kötüye kullanım, yetkisiz erişim, değişiklik ve imhadan korumak için tüm uygun fiziksel, teknik ve yönetsel prosedürleri kullanır.
    
    European, güvenliği sağlamak için şifreleme ve çok çeşitli kimlik doğrulama protokolleri kullanır. Kişisel bilgilerinizi aktarım sırasında korumak için (SSL) şifreleme gibi şifreleme kullanır. Ancak, İnternetteki hiçbir güvenlik sisteminin mükemmel olmadığını lütfen unutmayın.
    
    Tüm ödeme işlemleri şifrelenerek yapılır.
    
    Verilerinize Erişim ve Verilerinizi Düzeltme
    
    European tarafından tutulan kişisel verilere erişim, bunların kopyaları ve bunların düzeltilmesi veya silinmesini talep etme veya kişisel verilerinizin işlenmesine ilişkin onayınızı geri çekme hakkına sahipsiniz. Bu haklardan birini kullanmak isterseniz, privacy@delawaredasirket.com adresine e-posta gönderebilirsiniz.
    
    European bir temsilcisi, bu tür bir geri çekmenin sonuçlarını açıklayacak ve rızanın geri çekilmesi için işlemleri başlatacaktır.
    
    Değişikliğin herhangi bir yasayı veya yasal gerekliliği ihlal edeceğini düşünürsek, veri değiştirme talebini kabul edemeyeceğimizi lütfen unutmayın.
    
    Gizlilik Politikasında Değişiklik
    
    Gizlilik Politikamız zaman zaman değişebilir. Politikalarımızda herhangi bir önemli değişiklik yaparsak, bu sayfada değişiklik bildirimini yayınlayacağız.
    
    İletişim
    
    Gizlilik politikamız hakkında sorularınız varsa, bize şu kanaldan ulaşabilirsiniz:
    
    E-posta: privacy@delawaredasirket.com

    `;

    return (
        <Fragment>
            <Helmet>
                <title>Gizlilik Politikası | {site.title}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1">
                <Header/>
                <BlogDetailsContent sidebar={false} blogTitle="Gizlilik Politikası" blogContent={content}/>
                <Footer/>
            </LayoutDefault>
        </Fragment>
    );
};

export default PrivacyPolicy;
