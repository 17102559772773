import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const WorkFive = ({title, subtitle}) => {
    return (
        <div className="brook-whatdo-area ptb--15 ptb-sm--30 ptb-md--20 bg_color--1">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="bk-title--default text-center">
                            <h6 className="heading heading-h2 theme-color">{title}</h6>
                            <div className="bk-separator--30"/>
                            <h3 className="heading heading-h6">{subtitle}</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkFive;
