import React from 'react';
import Thumbnail from "components/about-us/thumbnail/AboutThumbOne";

const Article = ({title, content, thumbnail}) => {
    return (
        <article className="blog-post standard-post">
            <header className="header mb--40 text-center">
                <h3 className="heading heading-h3 font-32">
                    {title}
                </h3>
            </header>

            {thumbnail ? <Thumbnail thumb={thumbnail} className="mb--60"/> : null}

            <section className="content basic-dark2-line-1px pb--50 mb--35">
                <div className="inner">
                    <div className="desc mt--45 mb--50">
                        <p className="bk_pra wow move-up">
                            {content}
                        </p>
                    </div>
                </div>
            </section>
        </article>
    );
};

export default Article;
